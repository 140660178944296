.result-card {
    display: flex;
    flex-direction: column; /* Permite que el contenido se alinee verticalmente */
    justify-content: space-between; /* Asegura que el contenido principal esté arriba y el botón abajo */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
    height: 100%; /* Asegura que todas las tarjetas llenen el espacio disponible */
    min-height: 400px; /* Define una altura mínima para que no haya tarjetas muy pequeñas */
    padding: 10px;
}

.result-card:hover {
    transform: translateY(-5px);
}

.result-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}
.result-card:hover img {
    transform: scale(1.03); /* Efecto de zoom en la imagen */
}

.card-content {
    position: relative;
    padding: 15px;
    flex-grow: 1; /* Permite que el contenido de texto crezca para llenar el espacio restante */
    display: flex;
    flex-direction: column;
}
h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.price {
    font-size: 1.2em;
    color: #007BFF;
    margin-bottom: 10px;
}

.old-price {
    text-decoration: line-through;
    color: #888;
    font-size: 0.9em;
}

/* Tooltip */
.price-tooltip {
    position: absolute;
    background: #ffffff; /* Fondo blanco */
    border: 1px solid #ddd; /* Borde similar al del contenedor principal */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    color: #333; /* Texto en gris oscuro */
    padding: 10px 15px; /* Espaciado interno */
    /*border-radius: 8px;*/ /* Bordes redondeados */
    font-size: 0.9rem; /* Tamaño de texto moderado */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
    top: 120%; /* Posición debajo del precio */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; /* Evita que el texto se corte en líneas */
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    visibility: hidden; /* Por defecto, no visible */
    opacity: 0; /* Transparente por defecto */
}

.price-tooltip.show {
    visibility: visible;
    opacity: 1;
}

.price-tooltip ul {
    margin: 0;
    padding: 0;
    list-style-type: none; /* Sin viñetas */
}

.price-tooltip li {
    padding: 5px 0; /* Espaciado entre precios */
    border-bottom: 1px solid #eee; /* Línea divisoria entre ítems */
}

.price-tooltip li:last-child {
    border-bottom: none; /* Sin línea en el último elemento */
}

.price-tooltip::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ddd transparent; /* Triángulo apuntando hacia arriba */
}

.description {
    font-size: 1em;
    color: #555;
    margin-bottom: 15px;
    flex-grow: 1; /* Permite que la descripción crezca según sea necesario */
}

.destination {
    font-size: 1em;
    color: rgb(52, 60, 147);
    margin-bottom: 15px;
    flex-grow: 1; /* Permite que la descripción crezca según sea necesario */
}

.tag {
    font-size: 0.9rem;
    color: #666;
}

/* Botón CTA */
.cta-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.1em;
    transition: background-color 0.3s ease;
}

.cta-btn:hover {
    background-color: #0056b3;
}

/* Responsive */
@media (max-width: 768px) {
    
}