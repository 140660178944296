.splide__pagination__page.is-active {
    background-color: #007BFF!important;
}

.experience-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Asegura que los contenidos se distribuyan bien */
    width: 100%;
    height: 100%; /* Obliga a que todos tengan la misma altura */
    background: #fff;
   /* border: 1px solid #ddd;*/
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    /*transition: all 0.3s ease;*/
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
    position: relative;
}

.experience-item:hover {
    transform: translateY(-5px);
}

.experience-item .experience-content {
    padding: 10px; /* Asegura que el contenido no se corte */
}

.experience-item figure {
    margin: 0; /* Elimina cualquier margen que cause desplazamiento */
}

.experience-item img {
    display: block; /* Evita el espacio extra debajo de la imagen */
    width: 100%;
    height: auto;
    transition: transform 0.3s ease; /* Transición suave en la imagen */
}

.experience-item:hover img {
    transform: scale(1.05); /* Efecto de zoom en la imagen */
}



/* Variante pequeña */
.experience-item-small {
    font-size: 0.8rem;
}

/* Variante mediana */
.experience-item-medium {
    font-size: 1rem;
}

/* Variante grande */
.experience-item-large {
    font-size: 1.2rem;
}

.experience-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    &:hover {
        cursor: pointer;
    }
}
/*
.experience-image:hover {
    cursor: pointer;
}
*/

.experience-content {
    flex-grow: 1; /* Toma el espacio restante para uniformar las alturas */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.experience-title {
    font-size: 1.2rem;
    margin: 10px 0;
}

.experience-price {
    font-size: 1rem;
    color: #007BFF;
}

.experience-description {
    font-size: 0.9rem;
    margin: 10px 0;
    color: #555;
    overflow: hidden; /* Ocultar contenido que exceda */
    text-overflow: ellipsis; /* Mostrar "..." si el texto es muy largo */
    white-space: nowrap; /* Mantener una sola línea */
}

.experience-content {
    flex-grow: 1; /* Ocupa todo el espacio disponible en el ítem */
}

.reserve-button {
    margin-top: auto; /* Mover el botón al final */
}

.experience-destination,
.experience-category,
.experience-tag {
    font-size: 0.9rem;
    color: #666;
}
.reserve-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reserve-button:hover {
    background-color: #0056b3;
}

.reserve-button:focus {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
}

/* Tooltip */
.experience-tooltip {
    position: absolute;
    background: #ffffff; /* Fondo blanco */
    border: 1px solid #ddd; /* Borde similar al del contenedor principal */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    color: #333; /* Texto en gris oscuro */
    padding: 10px 15px; /* Espaciado interno */
    /*border-radius: 8px;*/ /* Bordes redondeados */
    font-size: 0.9rem; /* Tamaño de texto moderado */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
    top: 120%; /* Posición debajo del precio */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; /* Evita que el texto se corte en líneas */
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    visibility: hidden; /* Por defecto, no visible */
    opacity: 0; /* Transparente por defecto */
}

.experience-tooltip.show {
    visibility: visible;
    opacity: 1;
}

.experience-tooltip ul {
    margin: 0;
    padding: 0;
    list-style-type: none; /* Sin viñetas */
}

.experience-tooltip li {
    padding: 5px 0; /* Espaciado entre precios */
    border-bottom: 1px solid #eee; /* Línea divisoria entre ítems */
}

.experience-tooltip li:last-child {
    border-bottom: none; /* Sin línea en el último elemento */
}

.experience-tooltip::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ddd transparent; /* Triángulo apuntando hacia arriba */
}

p[itemProp="offers"] {
    position: relative; /* Asegura que el tooltip se posicione relativo a este elemento */
}

.info-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #007BFF; /* Azul para resaltar */
    transition: transform 0.2s;
}

.info-button:hover {
    transform: scale(1.1);
}
