/* Estilos del footer */
footer {
    background-color: #e2e2e2;
    border-top: 1px solid #ccc;
    color: rgb(43, 43, 43);
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer .logos {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    align-items: center;
}

footer .logos a img {
    max-width: 50%;
}

footer p {
    margin: 0;
}

/* Responsive */
@media (max-width: 768px) {
    footer .logos {
        flex-direction: column;
        gap: 10px;
    }
}