/* Estilos para el body */
main {
    padding: 20px;
}

.results-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    max-width: 1200px;
    margin: 0 auto;
}