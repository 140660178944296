header {
    background-color: #fff;
    padding: 10px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
}

header h1 {
    font-size: 3.5rem;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    width: 100%;
    text-align: center;
}

header .logo {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

header .logo img {
    margin-right: 30px;
    max-width: 150px;
}

.filters {
    margin-top: 30px; /* Añade espacio entre los filtros y el logo/título */
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between; /* Distribuye los filtros horizontalmente */
    flex-wrap: wrap; /* Permite que los filtros se muevan a una nueva línea en pantallas más pequeñas */
    /*gap: 10px;*/
}

header .filters select {
    flex: 1;
    width: 33%; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

header .text-provider {
    font-size: 2.3em
}

/* Responsive */
@media (max-width: 768px) {
    header {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    header h1 {
        text-align: center;
        font-size: 1.5rem;
        margin: 0 0 10px 0;
    }

    header .text-provider {
        font-size: 1.5em
    }
    
    header .logo {
        flex-direction: column;
        align-items: center;
    }

    header .logo img {
        margin-right: 0;
        margin-bottom: 5px;
        max-width: 100px;
    }

    header .filters {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 0
    }

    header .filters select {
        width: 100%;
        margin: 0 100px;
    }
}