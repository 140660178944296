.select-input-label {
    width: 33% !important;
}

.MuiMenuItem:hover {
    background-color: rgba(0,123,255,0.77) !important;
    color: white !important;
}

.MuiMenuItem.Mui-selected {
    background-color: #007bff !important;
    color: white;
}

.MuiMenuItem.Mui-selected:hover {
    background-color: rgba(0,123,255,0.77) !important;
}

/* Responsive */
@media (max-width: 768px) {
    .select-input-label {
        width: 100% !important;
        margin: 5px 0 !important;
    }
    .MuiMenu-root {
        max-height: 70% !important;
    }
}

